import React from 'react';
import qs from 'query-string';
import AdminUserStampSection from '../../Generators/AdminResource/AdminUserStampSection';
import AdminUserStampSectionV2 from '../../Generators/AdminResource/AdminUserStampSectionV2';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

export default function AdminUserStamp(props) {
  const {owner} = qs.parse(props.location.search);
  const [profile, setProfile] = React.useState();
  React.useEffect(() => {
    (async () => {
      if (owner) {
        try {
          let resp = await JStorage.fetchOneDocument('user_profile', {
            owner: owner,
          });
          setProfile(resp);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [owner]);

  return (
    <div style={{padding: 20}}>
      <h1>印花集章</h1>
      <div>使用者名稱：{profile?.name} </div>
      <div style={{height: 20}} />

      {/* <AdminUserStampSection owner={owner} /> */}
      {/* <div style={{height: 20}} /> */}
      <AdminUserStampSectionV2 owner={owner} />
    </div>
  );
}
