import React from 'react';
import * as Ant from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';

// const status = ['AVAILABLE', 'REDEEMED', 'VOIDED'];

const TABS = {
  my: 'my',
  obtain: 'obtain',
  exchange: 'exchange',
};

const columns = [
  {
    title: '訂單編號',
    key: 'order_id',
    dataIndex: 'order_id',
  },
  {
    title: '活動名稱',
    key: 'event_name',
    dataIndex: 'event_name',
  },
  {
    title: '分店',
    key: 'store_name',
    dataIndex: 'store_name',
  },
  {
    title: '取得印花',
    key: 'count',
    dataIndex: 'count',
  },
  {
    title: '取得印花日期',
    key: 'created',
    dataIndex: 'created',
    render: (value) => new Date(value).toLocaleString('sv'),
  },
  {
    title: '兌換印花',
    key: 'used',
    dataIndex: 'used',
  },
  {
    title: '兌換印花日期',
    key: 'redeem_time',
    dataIndex: 'redeem_time',
    render: (value) => (value ? new Date(value).toLocaleString('sv') : null),
  },
  {
    title: '狀態',
    key: 'status',
    dataIndex: 'status',
  },
];

const obtainColumns = [
  ,
  {
    title: '日期',
    dataIndex: 'created',
    key: 'created,',
  },
  {
    title: '訂單編號',
    dataIndex: 'order_id',
    key: 'order_id,',
  },
  {
    title: '門市',
    dataIndex: 'store_name',
    key: 'store_name,',
  },
  {
    title: '龍珠印花',
    dataIndex: 'count',
    key: 'count,',
  },
  {
    title: '龍珠雷達',
    dataIndex: 'obtain_count',
    key: 'obtain_count,',
  },
];

const exchangeColumns = [
  ,
  {
    title: '日期',
    dataIndex: 'redeem_time',
    key: 'redeem_time,',
  },
  {
    title: '名稱',
    dataIndex: 'note',
    key: 'note,',
  },
  {
    title: '門市',
    dataIndex: 'store_name',
    key: 'store_name,',
  },
  {
    title: '龍珠印花',
    dataIndex: 'count',
    key: 'count,',
  },
];

export default function AdminUserStampSectionV2(props) {
  const {owner} = props;

  const [stamps, setStamps] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [error, setError] = React.useState('');
  const [tab, setTab] = React.useState(TABS.my);

  React.useEffect(() => {
    (async () => {
      try {
        setError('');
        let resp = await JStorage.fetchAllDocuments('stamp_v2', {
          owner,
        });
        setStamps(resp);
      } catch (e) {
        console.warn(e);
        setError('發生錯誤');
      }
    })();
  }, [owner]);

  React.useEffect(() => {
    (async () => {
      let resp = await AppActions.fetchStores({allowAll: true});
      setStores(resp);
    })();
  }, []);

  const records = React.useMemo(() => {
    let groups = {};
    if (tab === TABS.my) {
      for (let stamp of stamps) {
        const key = stamp.order + stamp.status;
        if (!groups[key]) {
          let store = stores.find((s) => s.id === stamp.store);

          groups[key] = {
            order_id: stamp.obtain_meta?.order_id,
            event_name: stamp.obtain_meta?.event_name,
            created: stamp.created,
            store_name: store?.name,
            redeem_time: stamp.redeem_meta?.redeem_time,
            count: 1,
            used: stamp.status === 'REDEEMED' ? 1 : 0,
            status: stamp.status,
          };
        } else {
          groups[key].count += 1;
          groups[key].used += stamp.status === 'REDEEMED' ? 1 : 0;
        }
      }
      return Object.values(groups);
    } else if (tab === TABS.obtain) {
      groups = stamps.reduce((sum, x) => {
        sum[x.order] = [...(sum[x.order] || []), x];
        return sum;
      }, {});
      return Object.keys(groups).map((key) => {
        let group = groups[key];
        return {
          created: new Date(group[0].created).toLocaleDateString(),
          order_id: group[0].obtain_meta?.order_id, // TODO chien
          store_name: stores.find((store) => store.id === group[0].store)?.name,
          count: group.filter(
            (stamp) => stamp.obtain_meta?.event_type === 'DRAGON_BALL_STAMP',
          ).length,
          obtain_count: group.filter(
            (stamp) => stamp.obtain_meta?.event_type === 'DRAGON_BALL',
          ).length, // TODO chien
        };
      });
    } else if (tab === TABS.exchange) {
      let groups = stamps
        .filter((x) => x.status === 'REDEEMED')
        .reduce((sum, x) => {
          sum[x.redeem_meta?.redeem_time] = [
            ...(sum[x.redeem_meta?.redeem_time] || []),
            x,
          ];
          return sum;
        }, {});
      return Object.keys(groups).map((key) => {
        let group = groups[key];
        return {
          redeem_time: new Date(
            group[0].redeem_meta?.redeem_time,
          ).toLocaleDateString('sv'),

          note: group[0].redeem_meta?.result.name,
          store_name: stores.find((store) => store.id === group[0].store)?.name,
          count: group.length,
        };
      });
    }
    return [];
  }, [stores, stamps, tab]);

  return (
    <div>
      <h1 style={{marginBottom: 10}}>集點卡</h1>
      {error && <div style={{color: 'red'}}>{error}</div>}

      <div style={{display: 'flex', marginBottom: 10}}>
        {Object.keys(TABS).map((key) => (
          <Ant.Button
            key={key}
            type={tab === key ? 'primary' : 'default'}
            style={{marginRight: 10}}
            onClick={() => setTab(key)}>
            {
              {
                my: '印花',
                obtain: '獲得紀錄',
                exchange: '兌換紀錄',
              }[key]
            }
          </Ant.Button>
        ))}
      </div>
      <Ant.Table
        dataSource={records}
        columns={
          tab === TABS.my
            ? columns
            : tab === TABS.obtain
            ? obtainColumns
            : tab === TABS.exchange
            ? exchangeColumns
            : []
        }
      />
    </div>
  );
}
